import { type ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import ThemeProvider from '@repo/theme';
import { SnackbarProvider } from 'notistack';
export const MAX_SNACKS = 3;
interface UiProviderProps {
  children: ReactNode;
}
const UiProvider = (props: UiProviderProps): React.JSX.Element => {
  const {
    children
  } = props;
  return <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-component="UiProvider" data-sentry-source-file="UiProvider.tsx">
      <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="UiProvider.tsx">
        <SnackbarProvider maxSnack={MAX_SNACKS} data-sentry-element="SnackbarProvider" data-sentry-source-file="UiProvider.tsx">{children}</SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>;
};
export default UiProvider;