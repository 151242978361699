'use client';

import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import ServerError500 from 'components/ServerError500';
const GlobalError = ({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) => {
  useEffect(() => {
    Sentry.captureException(error);
    if (window.newrelic) window.newrelic?.noticeError(error);
  }, [error]);
  return <html lang="en" data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <ServerError500 data-sentry-element="ServerError500" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
};
export default GlobalError;