'use client';

import { Box, Button } from '@mui/material';
import type { ErrorPageProps } from 'types/error';
const ErrorPage = ({
  reset
}: ErrorPageProps) => <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" data-sentry-element="Box" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx">
    <h2>Something went wrong!</h2>
    <Button variant="contained" onClick={() => reset()} data-sentry-element="Button" data-sentry-source-file="error.tsx">
      Try again
    </Button>
  </Box>;
export default ErrorPage;