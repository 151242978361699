'use client';

import { Box, Button, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'next/navigation';
import { FormTextField } from '@eventtemple/eventtemple-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ApiLocalStorageKey } from 'enums/api';
import { ImageSrc } from 'enums/images';
import { useMagicAuthUserActions } from 'features/magicAuthUser/hooks';
import type { AxiosError } from 'axios';
interface LoginFormData {
  email: string;
  api_url: string;
}
interface LoginFormProps {
  onSuccess: () => void;
  title?: string;
  emailHelperText?: string;
  submitBtnLabel?: string;
  bookingUuid?: string;
}
const LoginForm = ({
  onSuccess,
  title,
  emailHelperText,
  submitBtnLabel,
  bookingUuid
}: LoginFormProps): React.JSX.Element => {
  const queryParams = useSearchParams();
  const t = useTranslations();
  const {
    emailLoginLinkMutation
  } = useMagicAuthUserActions();
  const isPreviewApp = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  const schema = yup.object().shape({
    email: yup.string().email(t('invalidEmail')).required(t('isRequired'))
  });
  const methods = useForm<LoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      api_url: process.env.NEXT_PUBLIC_API_URL || ''
    }
  });
  const onSubmit = async (data: LoginFormData) => {
    localStorage.setItem(ApiLocalStorageKey.REVIEW_APP_API_URL, data.api_url);
    emailLoginLinkMutation.mutate({
      email: data.email,
      booking_uuid: bookingUuid || '',
      redirect_url: queryParams.get('redirect_url') || '/'
    }, {
      onSuccess,
      onError: handleOnError
    });
  };
  const handleOnError = (e: unknown) => {
    const error = e as AxiosError<{
      errors: [{
        detail: string;
      }];
    }>;
    let message = error?.response?.data?.errors?.[0]?.detail;
    if (message?.toLowerCase() === 'email not found') {
      message = `${t('emailNotFound')}. ${t('ifYouThinkYouShouldHaveAccess')}`;
    }
    methods.setError('email', {
      message
    });
  };
  return <Stack alignItems="center" rowGap={1} data-sentry-element="Stack" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <Box component="img" height={50} src={ImageSrc.IC_PASSWORD} alt="password" loading="lazy" data-sentry-element="Box" data-sentry-source-file="LoginForm.tsx" />
      <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="LoginForm.tsx">
        {title ?? `${t('signIn')} ${t('to').toLowerCase()} ${t('guestPortal')}`}
      </Typography>
      <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-source-file="LoginForm.tsx">
        <Box noValidate component="form" onSubmit={methods.handleSubmit(onSubmit)} width="100%" data-sentry-element="Box" data-sentry-source-file="LoginForm.tsx">
          <Stack data-sentry-element="Stack" data-sentry-source-file="LoginForm.tsx">
            <FormTextField margin="normal" label={`${t('email')} ${t('address', {
            count: 1
          })}`} name="email" type="text" required helperText={emailHelperText} fullWidth data-sentry-element="FormTextField" data-sentry-source-file="LoginForm.tsx" />

            {isPreviewApp ? <FormTextField margin="normal" label="API URL" name="api_url" required fullWidth /> : null}

            <Button data-testid="button-login" type="submit" loading={false} fullWidth variant="contained" sx={{
            my: 2
          }} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
              {submitBtnLabel ?? t('login')}
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Stack>;
};
export default LoginForm;