'use client';

import { Box, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import { ImageSrc } from 'enums/images';
const EmailSent = (): React.JSX.Element => {
  const t = useTranslations();
  return <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" rowGap={2} data-sentry-element="Box" data-sentry-component="EmailSent" data-sentry-source-file="EmailSent.tsx">
      <Box component="img" height={50} src={ImageSrc.IC_EMAIL_SENT} alt="password" loading="lazy" data-sentry-element="Box" data-sentry-source-file="EmailSent.tsx" />

      <Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="EmailSent.tsx">{t('checkYourEmail')}</Typography>

      <Typography variant="body2" align="center" data-sentry-element="Typography" data-sentry-source-file="EmailSent.tsx">
        {t('weSentYouInstructions')}
      </Typography>
    </Box>;
};
export default EmailSent;