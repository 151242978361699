import { Stack } from '@mui/material';
import { StaticAssets } from 'types/enums/staticAssets';
interface ServerError500Props {
  children?: React.ReactNode;
}
const ServerError500 = ({
  children
}: ServerError500Props) => <Stack height="100%" flexDirection="column" justifyContent="center" alignItems="center" spacing={2} data-sentry-element="Stack" data-sentry-component="ServerError500" data-sentry-source-file="ServerError500.tsx">
    {children}

    <img height="260" width="350" src={StaticAssets.SERVER_ERROR} alt="Server Error" />
  </Stack>;
export default ServerError500;