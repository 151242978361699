'use client';

import 'styles/application.scss';
import { LicenseInfo } from '@mui/x-license';
import NewRelicAgent from '@repo/new-relic-agent/NewRelicAgent';
import { SnackbarProvider } from 'notistack';
import QueryProvider from 'providers/QueryProvider';
import UiProvider from 'providers/UiProvider';
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY ?? '');
interface AppLayoutProps {
  children: React.ReactNode;
}
declare global {
  interface Window {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    newrelic: any;
  }
}
const AppLayout = ({
  children
}: AppLayoutProps) => <html lang="en" translate="no" suppressHydrationWarning data-sentry-component="AppLayout" data-sentry-source-file="layout.tsx">
    <head>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" sizes="64x64" />
      <link rel="icon" type="image/x-icon" href="/favicon-48x48.ico" sizes="48x48" />
      <link rel="icon" type="image/x-icon" href="/favicon-32x32.ico" sizes="32x32" />
      <link rel="icon" type="image/x-icon" href="/favicon-16x16.ico" sizes="16x16" />
    </head>
    <NewRelicAgent data-sentry-element="NewRelicAgent" data-sentry-source-file="layout.tsx" />
    <body suppressHydrationWarning>
      <UiProvider data-sentry-element="UiProvider" data-sentry-source-file="layout.tsx">
        <QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="layout.tsx">
          <SnackbarProvider maxSnack={3} data-sentry-element="SnackbarProvider" data-sentry-source-file="layout.tsx">{children}</SnackbarProvider>
        </QueryProvider>
      </UiProvider>
    </body>
  </html>;
export default AppLayout;